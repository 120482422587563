@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    html {
        font-family: Poppins, system-ui, sans-serif;
    }

    .icon-white {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(136deg) brightness(200%) contrast(100%);
    }

    .shorten {
        background: url("./img/bg-shorten-mobile.svg");
    }

    .boost {
        background: url("./img/bg-boost-mobile.svg");
    }

    @media screen and (min-width: 640px) {
        .shorten {
            background: url("./img/bg-shorten-desktop.svg");
        }

        .boost {
            background: url("./img/bg-boost-desktop.svg");
        }
    }
}